import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Quote from "../../components/text-decorations/quote";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Quote mdxType="Quote">
  <p>"Nx is a set of extensible dev tools for monorepos, which helps you develop like Google, Facebook and Microsoft."</p>
  <a href="https://nx.dev/">Nx website</a>
    </Quote>
    <p>{`Nx makes it easier to work with monorepos, to and libraries that could share code between different apps. Nx also generates unit and e2e tests, it prepares bundling and code-splitting with Webpack. `}</p>
    <p>{`I've come in contact with Nx when I created notes for the `}<a parentName="p" {...{
        "href": "https://egghead.io/playlists/scale-react-development-with-nx-4038"
      }}>{`egghead course - Scale React Development with Nx`}</a>{` by Juri Strumpflohner. I'd recommend you to watch this free course. What I am going to share here, is just some of the notes that I took.`}</p>
    <h2 {...{
      "id": "nx-workspace",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nx-workspace",
        "aria-label": "nx workspace permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nx Workspace`}</h2>
    <p>{`A workspace contains different files and folders.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Apps`}</inlineCode>{` folder - contains all the applications we want to host`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Libs`}</inlineCode>{` folder - contains all the code, usually, applications will link to code inside this folder`}</li>
      <li parentName="ul">{`A single `}<inlineCode parentName="li">{`node_modules`}</inlineCode>{` folder for the workspace`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` serves as the global file for packages for the whole workspace`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`workspace.json`}</inlineCode>{` contains all the configuration of your projects and how to build it`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`nx.json`}</inlineCode>{` contains the configuration of the workspace`}</li>
    </ul>
    <h3 {...{
      "id": "installing-nx-and-creating-a-workspace",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-nx-and-creating-a-workspace",
        "aria-label": "installing nx and creating a workspace permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing Nx and Creating a workspace`}</h3>
    <p>{`To install Nx on your machine you can run the command: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install -g nx
`}</code></pre>
    <p>{`Or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn global add nx
`}</code></pre>
    <p>{`Then you can create and configure a new Nx workspace with the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx create-nx-workspace <workspace name>
`}</code></pre>
    <p>{`When you run this command, the CLI will give you a set of options that allows you to create the best workspace for your needs.`}</p>
    <ul>
      <li parentName="ul">{`empty`}</li>
      <li parentName="ul">{`oss`}</li>
      <li parentName="ul">{`web components`}</li>
      <li parentName="ul">{`angular`}</li>
      <li parentName="ul">{`angular-nest`}</li>
      <li parentName="ul">{`react`}</li>
      <li parentName="ul">{`react-express`}</li>
    </ul>
    <p>{`You will also be asked which CLI should be activated in this workspace. The `}<inlineCode parentName="p">{`Angular CLI`}</inlineCode>{` or the `}<inlineCode parentName="p">{`Nx CLI`}</inlineCode>{` which is used for any workspace.`}</p>
    <Note mdxType="Note">
  You can append the flag <code>--dry-run</code> to each command, to see what a command will do, without actual changes to your workspace.
    </Note>
    <h2 {...{
      "id": "creating-a-new-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-a-new-app",
        "aria-label": "creating a new app permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a new App`}</h2>
    <p>{`You can generate apps by using plugins for your Nx CLI. The egghead course is using React so this is what I am going to use. I am also going to be using `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` in these examples, but you could use `}<inlineCode parentName="p">{`npm`}</inlineCode>{` instead.`}</p>
    <p>{`First, we need to install the react plugin with the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add @nrwl/react
`}</code></pre>
    <Note mdxType="Note">
  You can run the command <code>yarn list @nrwl/react</code> to see all the things you can do with this plugin.
    </Note>
    <p>{`After the plugin is installed, you can run the following command to generate a new app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx generate @nrwl/react:application <name>
`}</code></pre>
    <p>{`You can also replace `}<inlineCode parentName="p">{`generate`}</inlineCode>{` for `}<inlineCode parentName="p">{`g`}</inlineCode>{` to make this command a bit shorter.`}</p>
    <h2 {...{
      "id": "creating-a-react-library",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-a-react-library",
        "aria-label": "creating a react library permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a React Library`}</h2>
    <p>{`To generate a library to be used inside an application, you can run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx g @nrwl/react:lib <name of library>
`}</code></pre>
    <p>{`When dealing with monorepos, organizing your workspace is important. So you can append the flag `}<inlineCode parentName="p">{`--directory=<name of directory>`}</inlineCode>{` to put new libraries inside a directory that contain the name of the app that will be using this library.`}</p>
    <h2 {...{
      "id": "installing-npm-packages-in-an-nx-workpsace",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-npm-packages-in-an-nx-workpsace",
        "aria-label": "installing npm packages in an nx workpsace permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing npm packages in an Nx workpsace`}</h2>
    <p>{`Since you are using a monorepo, you will have a global `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` which means that you can install your packages as normal, by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add <package name>
`}</code></pre>
    <h2 {...{
      "id": "creating-a-react-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-a-react-component",
        "aria-label": "creating a react component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a React Component`}</h2>
    <p>{`When generating a component, you can add the flag `}<inlineCode parentName="p">{`--project=<path and name of the library>`}</inlineCode>{` to specify which library should use this component. Let's assume that you have a library called `}<inlineCode parentName="p">{`my-awesome-lib`}</inlineCode>{` and you are creating the component `}<inlineCode parentName="p">{`header`}</inlineCode>{` you need to set the project flag like such: `}<inlineCode parentName="p">{`--project=my-awesome-lib-header`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx g @nrwl/react:component <name of component> --project=<name of library>
`}</code></pre>
    <p>{`Now that you have the component created, how can you import it?`}</p>
    <p>{`Have a look at the `}<inlineCode parentName="p">{`tsconfig.base.json`}</inlineCode>{` file, look for the `}<inlineCode parentName="p">{`paths`}</inlineCode>{` parameter and you can use that to import your components.`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Header } from @my-workspace/my-awesome-lib/header
`}</code></pre>
    <h2 {...{
      "id": "visualising-your-monorepo-structure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#visualising-your-monorepo-structure",
        "aria-label": "visualising your monorepo structure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Visualising your Monorepo Structure`}</h2>
    <p>{`Nx comes with a visual tool that will create a graph containing all your dependencies and how each thing relates to another.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx dep-graph
`}</code></pre>
    <p>{`A few things that are good to know about the graph representation of your project:`}</p>
    <ul>
      <li parentName="ul">{`Applications are represented by squares`}</li>
      <li parentName="ul">{`Libraries are represented by circles`}</li>
      <li parentName="ul">{`The arrows show the libraries that an application imports`}</li>
    </ul>
    <h2 {...{
      "id": "running-commands-tests-and-linting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-commands-tests-and-linting",
        "aria-label": "running commands tests and linting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running Commands, tests and linting`}</h2>
    <p>{`When you create a workspace, Nx will set up a few things for you - tests, linting and any other needed commands.`}</p>
    <p>{`To run a command it's as easy as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn run <app/lib name>:<command>
`}</code></pre>
    <p>{`For example, you can run tests with the command `}<inlineCode parentName="p">{`yarn run my-awesome-app:test`}</inlineCode>{`.`}</p>
    <p>{`What if you want to run a command in more than one application/library? Nx has you covered as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx run-many --target=<command> --projects=<app/lib separated by comman> --parallel=<boolean>
`}</code></pre>
    <p>{`That's a lot of flags! Let's have a look at them:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`--target`}</inlineCode>{` is the type of command we want to run - we want to use serve to run both applications`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--projects`}</inlineCode>{` these are all the applications we want to run in the command, they are comma-separated`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--parallel`}</inlineCode>{` is used because we want to serve both projects together at the same time`}
        <ul parentName="li">
          <li parentName="ul">{`If we don't use it, Nx would run the first project until terminated, before starting the next one`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Let's see an example. If we want to run the frontend and backend of our `}<inlineCode parentName="p">{`awesome-project`}</inlineCode>{` we can use the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx run-many --target=serve --projects=awesome-frontend,awesome-backend --parallel=true
`}</code></pre>
    <p>{`We have to use the `}<inlineCode parentName="p">{`--parallel`}</inlineCode>{` flag because we want to run both the backend and frontend at the same time.`}</p>
    <h2 {...{
      "id": "improving-ci-runs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improving-ci-runs",
        "aria-label": "improving ci runs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improving CI runs`}</h2>
    <p>{`When working with monorepos and as our workspace grows, the slower CI will be. Nx provides an `}<inlineCode parentName="p">{`affected`}</inlineCode>{` command that will build only the things that have changed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx affected:<target> --base=<branch - defaults main>
`}</code></pre>
    <p>{`For example, you can run only the tests for only the files that changed in our app with the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn nx affected:test
`}</code></pre>
    <Note mdxType="Note">
  We can skip the <code>--base</code> flag since it will default to the main branch.
    </Note>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://egghead.io/playlists/scale-react-development-with-nx-4038"
        }}>{`Egghead Course - Scale React Development with Nx`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/eggheadio/eggheadio-course-notes/tree/master/scale-react-development-with-nx"
        }}>{`Egghead notes repository`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nx.dev/"
        }}>{`Nx website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nx.dev/latest/react/getting-started/why-nx"
        }}>{`Docs: Why Nx`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nx.dev/latest/react/plugins/workspace/builders/run-commands"
        }}>{`Docs: Nx run commands`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nx.dev/latest/react/cli/run-many#run-many"
        }}>{`Docs: Nx run-many`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nx.dev/latest/react/cli/affected#affected"
        }}>{`Docs: Nx affected`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      